// withRouter.js
import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Create the HOC to provide navigate, location, and params
const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let params = useParams();
    
    // Pass navigate, location, and params as props to the wrapped component
    return <Component {...props} navigate={navigate} location={location} params={params} />;
  }

  return ComponentWithRouterProp;
};

export default withRouter;
