import React, {Component } from 'react';
import {Link , Navigate} from 'react-router-dom';
class  Sidebar extends React.Component
{
  constructor(props) {
    super(props)
  
    this.state = {
        admin:localStorage.getItem('admin'),
      // //  name:localStorage.getItem('name'),
        checked:false,
        log: false,
        logout:false,
        str: false,
    }
    this.logout = this.logout.bind(this);
     this.handleChange = this.handleChange.bind(this);

     this.checkadmin(this);
  }
  handleChange(checked) 
  {
   
 this.setState({ checked   });
    
  }
  
      checkadmin  = async () =>
  {

   this.setState({admin: localStorage.getItem('admin')})

   
  if(this.state.admin){
// console.log('call')

    this.setState({admin: true})
 console.log('heret '+this.state.admin+' '+localStorage.getItem('admin'));
    }
   else 
    {
      // this.setState({str: true})
       this.setState({admin: false})
        console.log('heref '+this.state.admin+' '+localStorage.getItem('admin'));
    }
   }

 async componentWillMount()
  {

   

//   if(localStorage.getItem('admin')){
// // console.log('call')

//     this.setState({admin: true})
//  console.log('here '+this.state.admin);
//     }
//    else 
//     {
//       // this.setState({str: true})
//        this.setState({admin: false})
//         console.log('here '+this.state.admin);
//     }
   }

  
   
  logout()
  {
    // localStorage.setItem('str' , '');
    localStorage.clear();
    this.setState({ logout: true})
localStorage.setItem('loginstatus' , 'false');
  }
  
    websitezenfood(keyword)
 {
        console.log(keyword);
  // window.location.href = `mailto:${this.props.email}`;
        // window.location.href = 'http://circularbyte.com/'; 

        if(keyword==='about')
        {
        window.open('https://circularbyte.com');
        }
        else if(keyword==='services')
        {
        window.open('https://circularbyte.com');
        }
         else if(keyword==='terms')
        {
        window.open('https://circularbyte.com');
        }
         else if(keyword==='contact')
        {
        window.open('https://circularbyte.com');
        }


 }

  togglevehicles() {

  
     localStorage.setItem('searchid','empty');

  }

  render()
  {

     const isadmin = localStorage.getItem('admin');

  if( this.state.logout)
  {
    return(<Navigate to = "/" />)
  }






  return (
    <aside className="main-sidebar sidebar-green-primary elevation-4" >
      <Link >
       
        <span
          className="brand-text "
          style={{ color: "#40b93c", marginRight: 10 }}
        >
      
        </span>

       
      </Link>



      <div className="sidebar" >
        
        <div>
          
          <div className="image" style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <img
             src="assets/dist/img/logo.png"
           
              style={{ width: '180px', height: '140px' }}
              alt="TwoStapp"
            />
          </div>
      
         

              
        
        </div>
      


        <nav className="mt-2" style={{ color: "black" }}>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
            style={{ color: "black" }}
          >
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link">
                <i
                  className="fas fa-chart-line nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Dashboard</p>
              </Link>

              <li className="nav-item">
                <Link to="/ordertable" className="nav-link">
                  <i
                    className="fab fa-first-order nav-icon"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Orders</p>
                </Link>
              </li>

              {localStorage.getItem("admin") !== "true" ? (
              <li className="nav-item">
                <Link to="/viewpromo" className="nav-link">
                  <i
                    className="nav-icon fas fa-gifts"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Promo code</p>
                </Link>
              </li>
    ) : null}
              {localStorage.getItem("admin") === "true" ? (
                <li className="nav-item">
                  <Link to="/resturanttable" className="nav-link" onClick= {this.togglevehicles.bind(this)} >
                    <i
                      className="fas fa-building nav-icon"
                      style={{ color: "black" }}
                    ></i>
                    <p style={{ color: "black",fontSize:'16px' }}>Resturants</p>
                  </Link>
                </li>
              ) : null}
            </li>

            {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item">
                <Link to="/viewriders" className="nav-link">
                  <i
                    className="fas fa-biking nav-icon"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Couriers</p>
                </Link>
              </li>
            ) : null}

{localStorage.getItem("admin") === "true" ? (

null
) :  <li className="nav-item">
<Link to="/maincat" className="nav-link">
  <i
    className="fas fa-list-alt nav-icon"
    style={{ color: "black" }}
  ></i>
  <p style={{ color: "black",fontSize:'16px' }}>Main category</p>
</Link>
</li>}

{localStorage.getItem("admin") === "true" ? (

null
) :   <li className="nav-item">
<Link to="subcat" className="nav-link">
  <i
    className="fas fa-list-alt nav-icon"
    style={{ color: "black" }}
  ></i>
  <p style={{ color: "black",fontSize:'16px' }}>Sub category</p>
</Link>
</li>}

            {false ? (
              <li className="nav-item has-treeview">
                <Link to="/" className="nav-link">
                  <i
                    className=" nav-icon fas fa-store"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black" }}>
                    Stores
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="storelist" className="nav-link" onClick= {this.togglevehicles.bind(this)}>
                      <i
                        className="fas fa-list-alt nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black",fontSize:'16px' }}>Stores List</p>
                    </Link>
                  </li>
                 
                 
                  {/* <li className="nav-item">
                    <Link to="nextsub" className="nav-link">
                      <i
                        className="fas fa-list-alt nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black",fontSize:'16px' }}>Second Sub category</p>
                    </Link>
                  </li> */}
                </ul>
              </li>
            ) : null}

            <li className="nav-item" hidden="true">
              <Link to="cuisine" className="nav-link">
                <i
                  className="nav-icon fas fa-utensils"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Cuisine Categories</p>
              </Link>
            </li>

            {(localStorage.getItem("str") != "true" && localStorage.getItem("admin") != "true") ? (
              <li className="nav-item">
                <Link to="menutable" className="nav-link">
                  <i
                    className="nav-icon fas fa-file-signature"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Menus</p>
                </Link>
              </li>
            ) : null}

            {localStorage.getItem("str") === "true" ? (
              <li className="nav-item">
                <Link to="productview" className="nav-link">
                  <i
                    className="nav-icon fas fa-file-signature"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Products</p>
                </Link>
              </li>
            ) : null}

   {/* {localStorage.getItem("admin") === "true" ? (
            <li className="nav-item">
                <Link to="ratingtable" className="nav-link" onClick= {this.togglevehicles.bind(this)}>
                <i
                  className="fas fa-star nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black" }}> Ratings</p>
              </Link>
            </li>
   ) : null} */}

            {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item">
                <Link to="/complains" className="nav-link">
                  <i
                    className="nav-icon fas fa-angry"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Complains</p>
                </Link>
              </li>
            ) : null}

{localStorage.getItem("admin") === "true" ? (
              <li className="nav-item">
                <Link to="/requests" className="nav-link">
                  <i
                    className="fas fa-file-invoice nav-icon"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Requests</p>
                </Link>
              </li>
            ) : null}


            {/* {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i
                    className="nav-icon fas fa-file-invoice"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black" }}>
                    Required Documents
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="documents" className="nav-link">
                      <i
                        className="fas fa-file-invoice nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>
                        Documents For Resturants
                      </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="docrider" className="nav-link">
                      <i
                        className="fas fa-file-invoice nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>
                        {" "}
                        Documents For Couriers
                      </p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null} */}

            {/* {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item">
                <Link to="earn" className="nav-link">
                  <i
                    className="nav-icon fas fa-hand-holding-usd"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black" }}>Earning Reports</p>
                </Link>
              </li>
            ) : null} */}

            {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item">
                <Link to="/viewuser" className="nav-link">
                  <i
                    className="nav-icon fas fa-user-friends"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black",fontSize:'16px' }}>Users</p>
                </Link>
              </li>
            ) : null}

            {localStorage.getItem("admin") === "true" ? (
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i
                    className="nav-icon fas fa-cog"
                    style={{ color: "black" }}
                  ></i>
                  <p style={{ color: "black" }}>
                    Settings
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  {/* <li className="nav-item">
                    <Link to="generalsetting" className="nav-link">
                      <i
                        className="fas fa-globe-asia nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>Generel Settings</p>
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link to="com" className="nav-link">
                      <i
                        className="fas fa-dollar-sign nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>Commissions Management</p>
                    </Link>
                  </li> */}
                  
                  <li className="nav-item">
                    <Link to="/deliverycharges" className="nav-link">
                      <i
                        className="fas fa-globe-asia nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black",fontSize:'16px' }}>General</p>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="vehicle" className="nav-link">
                      <i
                        className="fas fa-cogs nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" ,fontSize:'16px'}}>
                        Vehicle Type Management
                      </p>
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link to="push" className="nav-link">
                      <i
                        className="fas fa-bell nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>Push Notification</p>
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i
                        className="fas fa-ad nav-icon"
                        style={{ color: "black" }}
                      ></i>
                      <p style={{ color: "black" }}>Ads Banner</p>
                    </a>
                  </li> */}
                </ul>
              </li>
            ) : null}

            <li className="nav-item">
              <Link
                onClick={this.websitezenfood.bind(this, "about")}
                className="nav-link"
              >
                <i
                  className="fas fa-info nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>About Us</p>
              </Link>
            </li>

            <li className="nav-item">
              <Link  onClick={this.websitezenfood.bind(this, "services")} className="nav-link">
                <i
                  className="fab fa-servicestack nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Our Services</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link  onClick={this.websitezenfood.bind(this, "contact")} className="nav-link">
                <i
                  className="far fa-address-card nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Contact Us</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link  onClick={this.websitezenfood.bind(this, "terms")} className="nav-link">
                <i
                  className="far fa-question-circle nav-icon"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Terms and Conditions</p>
              </Link>
            </li>

            <li>
              <ul className="nav nav-treeview"></ul>
            </li>

            <li className="nav-item">
              <a onClick={this.logout} className="nav-link">
                <i
                  className="nav-icon fas fa-sign-out-alt"
                  style={{ color: "black" }}
                ></i>
                <p style={{ color: "black",fontSize:'16px' }}>Logout</p>
              </a>
            </li>

            <li className="nav-item">
              
                {/* <i
                  className="nav-icon far fa-copyright"
                  style={{ color: "black" }}
                ></i>
                <b style={{ color: "black" }}>@2020</b> */}
            
            </li>

              <li className="nav-item">
             
            </li>
             <li className="nav-item">
             
            </li>
            <li className="nav-item">

            </li>


          </ul>
        </nav>
      </div>
    </aside>
  );
}
}
export default Sidebar;
