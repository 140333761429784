import React, {Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import Content from './Content';
import {Link , BrowserRouter,Navigate} from 'react-router-dom';
import { Button, Form,FormText, Col, Row, Label,Dropdown,ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,  Modal, ModalHeader, FormGroup ,ModalBody, ModalFooter,InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

class Complains extends Component
{  
  state = {
    bankmodal:false,
    iteminfo:{bank_information:{bankname:	"",
    iban	:"",
    SWIFT:	""}},
    _id:"",
    newOrderModal: false,
    vehiclemodal:false,
  Items:[],
  withdrawalstatus:"",
  image_path:'',
  image: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg',
  image_base64: '',
 };

 constructor() {
   super();
   this.getItems();
     this.handleDeleteRow = this.handleDeleteRow.bind(this);
      //  this.onClick = this.onClick.bind(this);
 
    
 }

 handleSubmit = event =>{

  
  const dbData = new FormData();
  dbData.append('image', this.state.image_base64);
  fetch('https://foodbytes.codingustad.com/cwp-upload/uploadbaseimage.php', {
    method: 'POST',
    body: dbData,
  })
    .then(response => response.json())
    .then(responseJson => {
      console.log('RESULTS HERE:', responseJson);
      console.log('RESULTS HERE:', responseJson.msg);
      this.setState(

        {
          image_base64: responseJson.imagepath,

        },

      );

      if (responseJson.success === 1) {

const url = "https://foodbytes.codingustad.com/rest_apis/updaterequests";
const data = { 
  _id:this.state._id,
  status : this.state.withdrawalstatus ,
   verification_document: 'https://foodbytes.codingustad.com/cwp-upload/' + this.state.image_base64, 
 
  }
fetch(url, { method: 'POST', 
headers: {
  'Content-Type': 'application/json',
},
body: JSON.stringify(data),
 })
.then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {
// alert ('registered successfully'  , response);
console.log('Success:', response)

  if (response.status === true) {
    // this.props.history.push('/requests');
    window.location.reload();
  }
}
); 


      }

    })
    .catch(error => {
      console.error(error);
    }); // end of if


 }


 handleClose = () => {
  this.setState({ bankmodal: false })
   this.setState({vehiclemodal : false })
}

 toggleNewOrders(_id)
 {
 
   this.setState({
    vehiclemodal: true,
    _id:_id,
   }) 
   
 }

 toggleNewbank(iteminfo)
 {
 
   this.setState({
    bankmodal: true,
    iteminfo:iteminfo,
   }) 
   
 }


 onClick(){
   console.log(this.props.email);

        window.location.href = `mailto:${this.props.email}`;
    }

      emailuser()
 {
this.setState({vehiclemodal:true})
        console.log();
  // window.location.href = `mailto:${this.props.email}`;
        // window.location.href = "mailto:"+useremail;
 }

handleDeleteRow(i) {
    let Items = [...this.state.Items]
    Items.splice(i, 1)
    this.setState({ 
      Items: Items
    })
  }
  getItems = async () =>
    {
      try{

      

    let data = await axios({
      method: 'get' ,
      url:'https://foodbytes.codingustad.com/rest_apis/allrequests' 
    }).then(({ data}) =>
    data);
    console.log(data.data)
    this.setState({Items: data.data})
    }catch(err)
    {
      console.log(err)
    }
 }

 handleImageChange = event => {
  let image_path = event.target.files;
  console.log('datafiles' , image_path)
  let reader = new FileReader();
  reader.readAsDataURL(image_path[0])
  reader.onload =(e) =>
  {
    console.log('data' , e.target.result);
    let bs64 = e.target.result.split('base64,');
    console.log('data', bs64[0]);
    console.log('base64 data', bs64[1]);

    this.setState({ image: e.target.result, image_base64: bs64[1]});
  }
 };

    render()
    {

       if (localStorage.getItem('admin') !== 'true') {
         return <Navigate to='/' />
    }
     if (localStorage.getItem("loginstatus") !== "true") {
       return <Navigate to="/" />;
     }

  return (
    <div class="wrapper">
      <Header />
      <Sidebar />
      <div class="content-wrapper">
        <section class="content-header">


        <Modal isOpen={this.state.bankmodal} onClick={this.handleClose} >
                   <ModalHeader style={{ background: '#40b93c', color: '#fff' }} ><p style={{ color: '#fff' }}>View Bank Details</p> </ModalHeader>
                   <ModalBody>
                     <Label> Bank Name </Label>
                     <FormGroup>

                       <Input type="text" value={this.state.iteminfo.bank_information.bankname} 
                        placeholder="Name" /></FormGroup>
                          <Label> Bank Name </Label>
                     <FormGroup>

                       <Input type="text" value={this.state.iteminfo.bank_information.iban} 
                        placeholder="Name" /></FormGroup>
                          <Label> Bank Name </Label>
                     <FormGroup>

                       <Input type="text" value={this.state.iteminfo.bank_information.SWIFT} 
                        placeholder="Name" /></FormGroup>

                   
                    
                   
                   

                   </ModalBody>
                   <ModalFooter>

                     <Button color="secondary" onClick={this.handleClose} >Cancel</Button>
                   </ModalFooter>
                 </Modal>

          
        <Modal isOpen={this.state.vehiclemodal} >
                   <ModalHeader style={{ background: '#40b93c', color: '#fff' }} ><p style={{ color: '#fff' }}>View Vehicle Details</p> </ModalHeader>
                   <ModalBody>
                     <Label> Transaction Document </Label>
                     <FormGroup>

                     <input type="file" ref = {fileInput => this.fileInput = fileInput}  style = {{color: 'red' , display:'none'}} name = "image_path"  onChange = {this.handleImageChange}/>
      
      <button onClick={() => this.fileInput.click()} style={{
        background: '#40b93c', color: '#fff',

        boxSizing: 'content-box',

        borderRadius:10,backgroundColor:"#40b93c",
        borderColor:"#40b93c",borderWidth:'0.5px',
      width:'120px',height:'35px'
      }} > Browse</button>

                      </FormGroup>
                     <Label>Withdrawal Status</Label>
                      <FormGroup  style={{marginBottom:10}}  >
                      <button onClick={() => 
                      {
                      this.setState({withdrawalstatus:"approved"})
                      this.handleSubmit()
                      }
                    } style={{
        background: '#40b93c', color: '#fff',
position:'absolute',left:10,
        boxSizing: 'content-box',

        borderRadius:10,backgroundColor:"#40b93c",
        borderColor:"#40b93c",borderWidth:'0.5px',
      width:'120px',height:'35px'
      }} > Mark Accepted</button>
      <button onClick={() => 
       {
        this.setState({withdrawalstatus:"rejected"})
        this.handleSubmit()
        }  
    } style={{
        background: '#40b93c', color: '#fff',
        position:'absolute',right:10,
        boxSizing: 'content-box',

        borderRadius:10,backgroundColor:"#40b93c",
        borderColor:"#40b93c",borderWidth:'0.5px',
      width:'120px',height:'35px'
      }} > Mark Rejected</button>

                     </FormGroup>
                    
                    
                    
                     <Label></Label>
                   

                   </ModalBody>
                   <ModalFooter>

                     <Button style={{marginTop:10}} color="secondary" onClick={this.handleClose} >Cancel</Button>
                   </ModalFooter>
                 </Modal>

          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1> </h1>
              </div>
              <div class="col-sm-12">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active">WithDrawal Requests</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title"> WithDrawal Requests </h3>
                </div>

                <div class="card-body">
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        {/* <th>User Name</th> */}
                        <th>User ID</th>

                        <th>Withdrawal Amount </th>

                        <th>Status </th>

                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Items.map((item, i) => (
                        <tr className="trow" key={i}>
                          {/* <td>{item.user_name}</td> */}

                          <td>{item.user_id}</td>
                          <td>{item.withdrawal_amount}</td>
                          <td>{item.status}</td>
                          <td>
                          {item.status==="pending" ?
                          <Button color="primary"   onClick={this.toggleNewOrders.bind(this,item._id)}
                           > Update Now</Button>
                           :null}
<Button color="primary" style={{marginLeft:15}}   onClick={this.toggleNewbank.bind(this,item)}
                           > Bank Info </Button>

                            {/* <button
                              onClick={this.toggleNewOrders.bind(this)}
                            >
                             Update Now
                            </button> */}

                            {/* <button
                              onClick={this.emailuser.bind(this)}
                            >
                              <i class='fas fa-window-close'></i>

                            
                            </button> */}

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
    }

}
export default Complains