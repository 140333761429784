import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database'; // Import other services as needed
import { getAuth } from 'firebase/auth'; // For authentication, if used

const firebaseConfig = {
  apiKey: "AIzaSyDqzmKZuu_U9L2RVBnNQQNI_1K3JLv0IRY",
  authDomain: "foodbytes-204ae.firebaseapp.com",
  databaseURL: "https://foodbytes-204ae-default-rtdb.firebaseio.com",
  projectId: "foodbytes-204ae",
  storageBucket: "foodbytes-204ae.appspot.com",
  messagingSenderId: "248966951596",
  appId: "1:248966951596:web:fca1978a988347d1d8e509",
  measurementId: "G-1X5DWCPEWV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize other services if needed
const database = getDatabase(app); // For Realtime Database
const auth = getAuth(app); // For Firebase Authentication

export { app, database, auth }; // Export as needed