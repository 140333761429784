import React, {Component } from 'react';
import Content from './Content';
import {BrowserRouter} from 'react-router-dom';

class App extends React.Component
{
  constructor(props) {
    super(props)
  
    this.state = {
       loggedInStatus:'NOT_LOGGED_IN',
       user: {}
    }
  }
  
render()
{

  return(
    <BrowserRouter>
          <div>
     <Content />
      </div>
      </BrowserRouter>

  );
}
}
export default App;
